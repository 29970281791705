<template lang="pug">
.lh-add-input(:class="classAddInput")
    .lh-add-input__field
      input(
        ref="input"
        :placeholder="placeholder"
        :maxlength="maxLengthInput"
        :value="value"
        @input="$emit('input', $event)"
        @keyup.enter="onEnter"
        @keyup.esc="onEscape"
        @blur="onBlur"
      )
    .lh-add-input__button(@click="onEnter" :class="classButton")
      .lh-add-input__button-icon.lh-add-input__button-icon--add
        include ../_assets/add.svg
      .lh-add-input__button-icon.lh-add-input__button-icon--tick
        include ../_assets/tick-big.svg
</template>

<script lang="ts">
  export default defineNuxtComponent({
    name: 'AddInput',
    props: {
      value: {
        type: String,
        required: true,
      },
      placeholder: {
        type: String,
        default: '',
      },
      maxLengthInput: {
        type: Number,
        default: null,
      },
      theme: {
        type: String,
        default: '',
      },
    },
    data: () => ({
      inputValue: '',
    }),
    computed: {
      classButton(): any {
        return `lh-add-input__button--${this.value.trim() ? 'tick' : 'add'}`;
      },
      classAddInput(): any {
        return this.theme ? `lh-add-input--${this.theme}` : null;
      },
    },
    mounted() {
      // TODO
      // для ios по задаче #1phjdfa
      // вариант не подходит, тк на реальных устройствах
      // если не скроллить - инпут перекрывается клавиатурой
      // по возможности найти другой вариант
      // const scrollTop = window.scrollY;
      // window.addEventListener(
      //   'scroll',
      //   () => {
      //     window.scrollTo(0, scrollTop);
      //   },
      //   {
      //     passive: true,
      //     once: true,
      //   },
      // );
      (this.$refs.input as HTMLInputElement)?.focus({ preventScroll: true });
    },
    methods: {
      onEnter(): void {
        (this.$refs.input as HTMLInputElement)?.blur();
        this.$emit('input-enter', this.value.trim());
      },
      onEscape(): void {
        (this.$refs.input as HTMLInputElement)?.blur();
        this.$emit('input-escape');
      },
      onBlur(): void {
        this.$emit('input-blur');
      },
    },
  });
</script>

<style lang="scss" src="./AddInput.scss"></style>
